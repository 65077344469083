import React from "react";

import ColorToggler from "./ColorToggler";
import Symbol from "./Symbol";

const Header = () => {

  return (
    <header>
      <ColorToggler>toggle colors</ColorToggler>
      <div className="icons-wrapper">
        <Symbol id="1" />
        <Symbol id="2" />
        <Symbol id="3" />
        <Symbol id="4" />
      </div>
    </header>
  );
};

export default Header;
