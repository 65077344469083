import React, { useEffect, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { interpolate } from "flubber";

import './Symbol.scss';

import sound1 from "./sounds/mixkit-negative-tone-interface-tap-2569.ogg";
import sound2 from "./sounds/mixkit-negative-tone-interface-tap-2569_t.ogg";
import sound3 from "./sounds/mixkit-negative-tone-interface-tap-2569_h.ogg";

const Symbol = (props) => {

  const plus     = "M 10.5,0.5 V 10.5 H 0.5 v 9 H 10.5 v 10 h 9 v -10 h 10 V 10.5 H 19.5 V 0.5 Z";
  const triangle = "M 15,1 L 29,29 L 1,29 L 15,1 Z";
  const square   = "M 2,2 L 28,2 L 28,28 L 2,28 L 2,2 Z";
  const circle   = "M 5, 15 a 10,10 0 1,1 20,0 a 10,10 0 1,1 -20,0";
  const icons    = [plus, triangle, square, circle];
  const timeouts = [ 800, 1000, 7000, 3000 ];
  let sounds     = [ new Audio(sound1), new Audio(sound2), new Audio(sound3) ];

  const [activeIcon, setActiveIcon] = useState(0);
  const [interpolator, setInterpolator] = useState( {state: () => circle });

  function rnd( max ) {
    // return 0 <= number < max
    return Math.floor( Math.random() * ( max + 1 ) );
  }

  function changeState() {
    let newIcon = rnd(3);

    setInterpolator({
      state: interpolate(
        icons[activeIcon],
        icons[newIcon],
        { maxSegmentLength: 1 }
      )
    })
    setActiveIcon(newIcon);
  }

  useEffect(() => {
    const timer = setTimeout(() => changeState(), timeouts[rnd(3)]);
    return () => clearTimeout(timer);
  })

  const animationProps = useSpring({
    from: { x: 0 },
    to:   { x: 1 },
    config: {
      clamp: true, // interpolation function can't go above 1
      duration: 500,
      easings: "easeInOutCubic"
    },
    reset: true,
  })

  function playRandomSound() {
    let i = rnd( sounds.length - 1 );
    sounds[i].muted = false;
    sounds[i].play();
  }

  function addClass() {
    document.querySelector(`#svg-${props.id}`).classList.add('mousedown');
  }
  function removeClass() {
    document.querySelector(`#svg-${props.id}`).classList.remove('mousedown');
  }

  return(
    <div
      className="symbol"
      onClick={() => playRandomSound()}
      onMouseDown={() => addClass()}
      onMouseUp={() => removeClass()}
      >
      <svg
        id={`svg-${props.id}`}
        className=""
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        width="30" height="30"
        role="img">
        <desc>Small wireframe logo morphing between circle, plus, triangle and square</desc>
        <animated.path d={animationProps.x.to(interpolator.state)} />
      </svg>
    </div>
  );
}

export default Symbol;
