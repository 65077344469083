import React, { useState, useEffect } from "react";

const Shuffle2 = ({children}) => {

  const [result, setResult] = useState('');

  useEffect(() => {

    if (result === '') {
      setResult(children);
      return;
    }

    if (String(result).trim() !== String(children).trim()) {
      const timer = setTimeout(() => {
        setResult(shuffleText(result, children))
      }, 15);
      return () => clearTimeout(timer);
    } else {
      setResult(children);
    }

  }, [result, children]);

  function getNextLetter(char) {

    // we'll deal later with all the printable characters
    // /^[a-z0-9!"#$%&'()*+,.\/:;<=>?@\[\] ^_`{|}~-]*$/

    return char.replace( /([a-zA-Z0-9\s])[^a-zA-Z0-9]*$/, function(a) {
      let currentCharCode = a.charCodeAt(0);

      switch(currentCharCode) {
        case 32: return 'A';     // if it's 'space' go to A-Z
        case 90: return 'a';     // if it's 'Z'     go to a-z
        case 122: return ' ';    // if it's 'z'     go to space
        default: return String.fromCharCode( ++currentCharCode );
      }
    });
  }

  function shuffleText( fromStr, toStr ) {

    // make the strings equally long
    if ( fromStr.length > toStr.length ) {
      while ( fromStr.length > toStr.length ) {
        toStr += ' ';
      }
    } else {
      while ( fromStr.length < toStr.length ) {
        fromStr += ' ';
      }
    }

    function loop( fromStr, toStr ) {

      let temp = '';

      if ( fromStr !== toStr ) {
        for ( let i = 0; i < fromStr.length; i++ ) {
          if ( fromStr[i] === toStr[i] ) {
            temp += fromStr[i];
          } else {
            temp += getNextLetter( fromStr[i] );
          }
        }
      }

      return temp;
    }

    return loop( fromStr, toStr );
  }

  return (
    <>
      {result}
    </>
  )
}

export default Shuffle2;