import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";
import axios from "axios";
import { useParams } from 'react-router-dom';

import Menu from "./Menu";
import Shuffle2 from './Shuffle2';

import "./Single.scss";

const Single = (props) => {

  const [postData, setPostData] = useState([]);

  const pageTitle = 'remotedots';
  const easterEgg = 'radu feflea';

  const prevTitle = useRef();
  let { post_id } = useParams();

  if (!post_id)
      post_id = props.postId;

  useEffect(() => {

    // save previous title
    if (postData && postData.length !== 0) {
      prevTitle.current = postData.title.rendered;
    } else {
      prevTitle.current = 'remotedots';
    }

    // Fetch categories data.
    axios.get(`${process.env.REACT_APP_WP_REST_BASE}pages/${post_id}`)
      .then(
        (res) => {
          // on the homepage
          if (process.env.REACT_APP_WP_FRONTPAGE_ID === post_id) {

            // if comming from another page, display the easter egg first...
            if (prevTitle.current !== pageTitle) {
              let remotedotsTitle = { ...res["data"].title, rendered: easterEgg }
              setPostData({...res["data"], title: remotedotsTitle});

              // ...and after some time display page title again
              const timer = setTimeout(() => {
                let remotedotsTitle = { ...res["data"].title, rendered: pageTitle }
                setPostData({ ...res["data"], title: remotedotsTitle });
              }, 3000);
              return () => clearTimeout(timer);
            } else {
              let remotedotsTitle = { ...res["data"].title, rendered: pageTitle }
              setPostData({ ...res["data"], title: remotedotsTitle });
            }
          } else {
            setPostData(res["data"]);
          }
        },
        (err) => {
          setPostData({
            title: { rendered: err.response.status },
            content: { rendered: err.response.statusText }
          });
        }
      );
  }, [post_id]);

  return (
    <div>
      {Object.keys(postData).length > 0 && (
        <div>
          <h1 id="title">
            <Shuffle2>{postData.title.rendered}</Shuffle2>
            </h1>
          <Menu />
          <div>
            {parse(postData.content.rendered)}
          </div>
       </div>
     )}
    </div>
  );
};

export default Single;
