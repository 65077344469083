import React from "react";

//import styles from "./Footer.module.scss";

const Footer = () => {
 /*const markup = (
   <footer className={styles["site-footer"]}>
     <span>
       remotedots
     </span>
   </footer>
 );*/

  return (
    <footer className="footer-wrapper show">
      <p>(c) {new Date().getFullYear()} remotedots | This site uses no cookies.</p>
    </footer>
  );
};

export default Footer;
