import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import './menu.scss';

const Menu = () => {
	const [pages, setPages] = useState([]);

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_WP_REST_BASE}menu`).then((res) => {
			if (!!res["data"]) {
				let list = [];
				let isRoot = false;

				res["data"].forEach((element, index) => {
					let path = '';
					if (element.object_id === process.env.REACT_APP_WP_FRONTPAGE_ID) {
						isRoot = true;
						path = '/';
					}	else {
						path = `/pages/${element.object_id}`;
						isRoot = false;
					}

					list.push(
						<NavLink
							key={index}
							to={path}
							className={({isActive}) => ((isActive || isRoot) ? 'menu-item active' : 'menu-item') }
						>
							{element.title}
						</NavLink>
					);
				});

				setPages(list);
			}
		});
	}, []);

	return(
		<div className="menu">
			{pages}
		</div>
	);

};

export default Menu;
