import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Layout from "./components/Layout";
import Single from "./components/Single";
import ErrorPage from "./components/ErrorPage";

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

const frontpage_id = process.env.REACT_APP_WP_FRONTPAGE_ID;

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="page-wrapper">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Single postId={frontpage_id} />} />
              <Route path="pages/:post_id" element={<Single />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);
