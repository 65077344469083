import React from "react";

import "./ColorToggler.scss";

const ColorToggler = ({children}) => {

  function toggleColors() {
    document.body.classList.toggle("inverted");
  };

  return (
    <button
      className="color-toggler"
      onClick={() => toggleColors()}
      >
      {children}
    </button>
  );
};

export default ColorToggler;