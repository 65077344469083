import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div>
      <div>
        <h1>404</h1>
        <div>
          No page found. Return <Link to='/'>home</Link>.
        </div>
     </div>
    </div>
  );
};

export default ErrorPage;